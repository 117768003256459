import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { PageTemplate } from '@wingscms/hummingbird';

import Button from '../../../components/Buttons';
import StackedHeader from '../../../components/StackedHeader';
import Navigation, {
  GhostContainer
} from '../../../@wingscms/hummingbird/components/Navigation';
import Footer from '../../../@wingscms/hummingbird/components/Footer';

const TicketsButton = styled(Button)`
  max-width: 800px;
  margin: 20px auto;
  display: block;
  width: 100%;
  background-image: none;
  &:after {
    display: none;
  }
  &:hover {
    color: ${({ theme }) => theme.primaryColor};
    background-color: #000;
  }
  &:active {
    background-image: none;
  }
`;

export default props => {
  const {
    pageContext: {
      node,
      node: {
        menu: { items }
      },
      shareUrls
    }
  } = props;
  const [ticketsPage, setTicketsPage] = useState(false);

  useEffect(() => {
    if (
      typeof document !== 'undefined' &&
      (document.location.pathname === '/tickets/' ||
        document.location.pathname === '/tickets')
    ) {
      const imported = document.createElement('script');
      imported.src =
        'https://tickets.voordemensen.nl/angstfabriek/iframes/vdm_loader.js';
      document.head.appendChild(imported);
      // eslint-disable-next-line
      setTicketsPage(true);
    }
  }, []);

  return (
    <div>
      <Navigation items={items} shareUrls={shareUrls} />
      <GhostContainer />
      <StackedHeader node={node} />
      {ticketsPage && (
        <TicketsButton
          buttonText="KOOP TICKETS"
          onClick={e => {
            e.preventDefault();
            // eslint-disable-next-line no-undef
            vdm_calendar();
          }}
        />
      )}
      <PageTemplate.Main {...props} />
      {ticketsPage && (
        <TicketsButton
          buttonText="KOOP TICKETS"
          onClick={e => {
            e.preventDefault();
            // eslint-disable-next-line no-undef
            vdm_calendar();
          }}
        />
      )}
      <Footer />
    </div>
  );
};
